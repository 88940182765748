
import { defineComponent, onBeforeUpdate, onMounted, reactive} from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'TitleInput',
  props: {
    editionId: String,
    clipId: String,
    title: String,
  },
  setup(props, context){
    const store = useStore()
    const draft = reactive({
      title: props.title
    })

    return {
      store,
      draft
    }
  },
  methods: {
    handleTitleChange(e: any){
      this.draft.title = e.target.value
    },
    async handleTitleSave(){
      try {
        await this.store.dispatch("updateClipTitle", {
          editionId: this.$props.editionId,
          clipId: this.$props.clipId,
          title: this.draft.title
        })
      } catch (error) {
        console.error(error)
        alert("Aus irgendeinem Grund konnte der Titel nicht geändert werden. Bitte laden Sie die Seite neu und versuchen es noch einmal.")
      }
    },
    hasTitleChanged(){
      return this.title != this.draft.title
    }
  },
})
