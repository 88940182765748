<template>
  <textarea 
    :class="clipId"
    @change="handleDescriptionChange"
    :value="draft.description"
  ></textarea>
  <button v-if="hasDescriptionChanged()" @click="handleDescriptionSave">Speichern</button>
</template>

<script lang="ts">
import { defineComponent, onBeforeUpdate, onMounted, reactive} from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'DescriptionInput',
  props: {
    editionId: String,
    clipId: String,
    description: String,
  },
  setup(props, context){
    const store = useStore()
    const draft = reactive({
      description: props.description
    })

    return {
      store,
      draft
    }
  },
  methods: {
    handleDescriptionChange(e: any){
      this.draft.description = e.target.value
    },
    async handleDescriptionSave(){
      try {
        await this.store.dispatch("updateClipDescription", {
          editionId: this.$props.editionId,
          clipId: this.$props.clipId,
          description: this.draft.description
        })
      } catch (error) {
        console.error(error)
        alert("Aus irgendeinem Grund konnte die Beschreibung nicht geändert werden. Bitte laden Sie die Seite neu und versuchen es noch einmal.")
      }
    },
    hasDescriptionChanged(){
      return this.description != this.draft.description
    }
  },
})
</script>

<style scoped lang="scss">
textarea {
  width: 100%;
  height: 120px;
}
</style>
