/**
 * Returns the newest editionId out of an array of editio ids.
 * @param editionIds Array of edition ids (e.g. ["20211", "202054"])
 * @returns the newest editionId (e.g. "20211")
 * @returns undefined, if no editionId could be found.
 */
export function getLatestEditionId(editionIds: string[]): string | undefined {
  const compareNumbers = (a: number, b: number) => a - b;
  try {
    const newestYear = editionIds
      .sort()
      .reverse()[0]
      .substring(0, 4);
    const editionsInYear = editionIds.filter((editionId) =>
      editionId.startsWith(newestYear)
    );
    const episodesInYear = editionsInYear.map((editions) =>
      parseInt(editions.replace(newestYear, ''), 10)
    );
    const newestEpisode = episodesInYear.sort(compareNumbers).reverse()[0];
    return `${newestYear}${newestEpisode}`;
  } catch (error) {
    return undefined;
  }
}

