<template>
  <div class="login">
    <h1>Login</h1>
    <form @submit="handleLogin">
     <button v-if="!loginLoading" type="submit" :disabled="loginLoading">Anmelden</button>
      <LoadingSpinner v-else />

      <p v-if="loginFailed" class="error__loginFailed">
        <!--Die Passwort-Email-Kombination war leider inkorrekt.-->
        Bei der Anmeldung über Microsoft ist leider etwas schiefgegangen.
      </p>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default defineComponent({
  name: 'Login',
  setup(){
    const store = useStore()

    return {
      store
    }
  },
  data(){
    return {
      loginFailed: false,
      loginLoading: false
    }
  },
  methods: {
    async handleLogin(e: Event){
      e.preventDefault()
      
      try {
        this.loginLoading = true
        this.loginFailed = false
        await this.store.dispatch('signInUser')
        // If signin succeeds go to HOME -> else show error
        this.$router.push('/')
      } catch (error) {
        console.error("ERROR Signing in", error)
        this.loginFailed = true
      } finally {
        this.loginLoading = false
      }
    }
  },
  components: {
    LoadingSpinner
  }
})
</script>

<style scoped lang="scss">
.login {
  text-align: center;
}

form {
  max-width: 300px;
  margin: auto;

  input {
    display: block;
    margin-bottom: 10px;
    width: 100%;
  }
}
.error__loginFailed {
  color: tomato;
}
</style>