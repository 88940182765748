import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import firebaseConfig from '../../firebase.config.json'
import {ClipInterface} from '@/interfaces/ClipInterface'
import {EditionInterface} from '@/interfaces/EditionInterface'
import { getLatestEditionId } from '@/utils/getLatestEditionId'

// Initialize Firebase

export const firebaseApp      = firebase.initializeApp({
  apiKey: firebaseConfig.config.api_key,
  authDomain: firebaseConfig.config.auth_domain,
  projectId: firebaseConfig.config.project_id
})

export const firebaseDB       = firebaseApp.firestore()
export const firebaseAuth     = firebase.auth()
export const provider = new firebase.auth.OAuthProvider(firebaseConfig.authorization.provider);
provider.setCustomParameters({
  tenant: firebaseConfig.authorization.tenant
});

provider.addScope('user.read');

// Set up Adapters

export const db = {
  /**
   * Returns the latest edition (aka the edition with the highest number)
   * @returns a promise with the highest number edition
   */
  getLatestEdition: async (): Promise<EditionInterface | null> => {
    const editionsSnapshot = await firebaseDB
      .collection("editions")
      .orderBy("id", "desc")
      .limit(60)
      .get()
    if(editionsSnapshot.empty) return null

    const ids = editionsSnapshot.docs.map(doc => doc.data().id)
    const latestEditionId = getLatestEditionId(ids)
    const latestEdition = editionsSnapshot.docs.find(doc => doc.data().id === latestEditionId)?.data()

    return latestEdition ? {
      id: latestEdition.id,
      year: latestEdition.year,
      episode: latestEdition.episode,
    } : null
  },
  /**
   * Returns an array of clips objects for the given edition
   * @param editionId for example "202001"
   * @returns a promise with a boolean
   */
  getEditionClips: async (editionId: string): Promise<Array<ClipInterface>> => {
    const clipsSnapshot = await firebaseDB
      .collection('editions')
      .doc(editionId) 
      .collection('clips')
      .orderBy('firstPage', 'asc')
      .get()

  
    let clips = clipsSnapshot.docs.map(doc => {
      const docData = doc.data()

      return {
        audioUrl: docData.audioUrl,
        description: docData.description,
        duration: docData.duration,
        durationSSML: docData.durationSSML,
        episode: docData.episode,
        id: docData.id,
        imageUrl: docData.imageUrl,
        ressort: docData.ressort,
        title: docData.title,
        year: docData.year,
        synonyms: docData.synonyms,
        firstPage: docData.firstPage,
        lastPage: docData.lastPage
      }
    })

    return clips
  },
  /**
   * Set the synonyms of a given clip and edition
   * @param editionId for example "202001"
   * @param clipId for example "12384z-123srt-q34twert"
   * @param synonyms for example ["Politik", "Politiker"]
   * @returns TBD
   */
  updateClipSynonyms: async (editionId: string, clipId: string, synonyms: Array<string>): Promise<any> => {
    return await firebaseDB
      .collection('editions')
      .doc(editionId)
      .collection('clips')
      .doc(clipId)
      .update({
        synonyms
      })
  },
  /**
   * Set the title of a given clip and edition
   * @param editionId for example "202001"
   * @param clipId for example "12384z-123srt-q34twert"
   * @param title for example "Die Zwei-Milliarden-Bombe"
   * @returns TBD
   */
  updateClipTitle: async (editionId: string, clipId: string, title: string): Promise<any> => {
    return await firebaseDB
      .collection('editions')
      .doc(editionId)
      .collection('clips')
      .doc(clipId)
      .update({
        title
      })
  },
  /**
   * Set the description of a given clip and edition
   * @param editionId for example "202001"
   * @param clipId for example "12384z-123srt-q34twert"
   * @param description for example "Gerade bei S. Fischer rausgeflogen,[...]"
   * @returns TBD
   */
  updateClipDescription: async (editionId: string, clipId: string, description: string): Promise<any> => {
    return await firebaseDB
      .collection('editions')
      .doc(editionId)
      .collection('clips')
      .doc(clipId)
      .update({
        description
      })
  },

  /**
   * Set the description of a given clip and edition
   * @param editionId for example "202001"
   * @param clipId for example "12384z-123srt-q34twert"
   */
  deleteClip: async(editionId: string, clipId: string) => {
    try {
      const clip = await firebaseDB
        .collection('editions')
        .doc(editionId)
        .collection('clips')
        .doc(clipId);
      
      await clip.delete()
    } catch(error) {
      console.error(error);
    }
  }
}


export const auth = {
  /**
   * Checks whether a user is logged in
   * @returns a promise with a boolean
   */
  getCurrentAuthStatus: async () : Promise<boolean> => {

    return await new Promise((resolve, reject) => {
      const unsubscribe = firebaseAuth.onAuthStateChanged(async user => {
        unsubscribe() // Stop listening for changes (get the user only once)
        resolve(!!user)
      }, reject)
    })
  },

  signIn: async (): Promise<Boolean> => {
    await firebaseAuth.signInWithPopup(provider).then((result) => {
        const credential = result.credential!;
        return !!credential;
      })
      .catch((error) => {
        console.error(error)
        return false;
      });
    return false;
  },
  
  /**
   * Signs out a user in case they are signed
   * @returns a promise
   */
  signOut: async () : Promise<any> => await firebaseAuth.signOut(),

  requiredEmailActionParameters: ['mode', 'oobCode', 'apiKey'],
  availableEmailActionTypes: ['resetPassword']
}