
import { computed, defineComponent, getCurrentInstance, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import TagsInput from '@/components/TagsInput.vue'
import TitleInput from '@/components/TitleInput.vue'
import DescriptionInput from '@/components/DescriptionInput.vue'
import { ClipInterface } from '@/interfaces/ClipInterface'
import { SYNONYM_BLACKLIST } from '@/constants'

export default defineComponent({
  name: 'Home',
  setup(){
    const store = useStore()
    const edition = reactive({
      id: "" as string,
      isLoading: true,
    })
    const editionClips = computed(() => store.getters.clips)
    const usedTags = store.getters.clips.map((clip: ClipInterface) => clip.synonyms).flat(1)
    const synonymsBlacklist = computed(() => ([] as Array<string>).concat(usedTags, SYNONYM_BLACKLIST) )
    const editionId = ''
    const clipId = ''
    onMounted(async () => {
      try {
        // GET latest edition and load it into local store
        await store.dispatch('loadLatestEdition')
        edition.id = store.getters.edition.id
        // GET clips of the latest edition
        await store.dispatch('loadEditionClips', edition.id)
      } catch (error) {
        console.error(error)
        alert("Es gab einen Fehler beim Laden der aktuellen Ausgabe. Bitte versuchen Sie es später erneut.")
      } finally {
        edition.isLoading = false
      }
    })

    return {
      store,
      edition,
      editionClips,
      synonymsBlacklist,
      editionId,
      clipId
    }
  },
  data(){
    return {
      editionSearchId: "",
      isSearchFailed: false,
      table: 0
    }
  },
  methods: {
    async handleSearch(e: Event){
      e.preventDefault()

      try {
        this.edition.isLoading = true
        this.isSearchFailed = false
        await this.store.dispatch('loadEditionClips', this.editionSearchId)
        this.editionClips = this.store.getters.clips
        this.edition.id = this.editionSearchId
      } catch (error) {
        console.error(error)
        this.isSearchFailed = true
      } finally {
        this.edition.isLoading = false
      }
    },

    async deleteClip() {
      try {
        let editionId = this.editionId;
        let clipId = this.clipId;
        await this.store.dispatch('deleteClip', {editionId, clipId})
      } catch (error) {
        console.error(error);
      } finally {
        let myDialog:any = <any> document.getElementById("dialog")
        await myDialog.close();
        this.editionSearchId = this.editionId;
        let ev = new Event("click");
        this.handleSearch(ev);
        this.editionId = '';
        this.clipId = '';
        
      }
    },

    async open(editionId: any, clipId: any) {
      try {
        this.editionId = editionId;
        this.clipId = clipId;
        let myDialog:any = <any> document.getElementById("dialog")
        myDialog.showModal();
      } catch (error) {
        console.error(error)
      }
    },
    async close() {
      let myDialog:any = <any> document.getElementById("dialog")
      this.editionId = '';
      this.clipId = '';
      myDialog.close();
    }
  },
  components: {
    LoadingSpinner,
    TagsInput,
    TitleInput,
    DescriptionInput
  },
})
