<template>
  <input 
    :class="clipId"
    type="text"
    ref="inputEl"
    @change="handleTagsChange"
  >
  <transition name="fade">
    <p v-if="hint.isVisible" class="hint">{{hint.message}}</p>
  </transition>
  <button v-if="haveSynonymsChanged()" @click="handleTagsSave">Speichern</button>
</template>

<script lang="ts">
import { defineComponent, onBeforeUpdate, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import Tagify from '@yaireo/tagify'
import '@yaireo/tagify/dist/tagify.css'

export default defineComponent({
  name: 'TagsInput',
  props: {
    editionId: String,
    clipId: String,
    synonyms: Array,
    blacklist: Array,
  },
  setup(props, context){
    const hint = ref({message: '', isVisible: false, timeout: setTimeout(()=>{},0)})
    const store = useStore()
    const inputEl = ref(null)
    const draft = reactive({
      synonyms: props.synonyms
    })
    let tagInput: any = null

    const _blacklistWithoutThisSynonyms = () => props.blacklist?.filter(s => !props.synonyms?.includes(s))
    
    const _onAdd = (tag: any) => {
      const tagValue = tag.detail.data.value
      const tags = store.getters.tags
      if(tags.includes(tagValue.toLowerCase())){
        store.commit('addTag', tagValue)
        tagInput.trigger('invalid', { message: 'dublicate synonym'})
        tagInput.removeTags(tagValue)
      }else{
        store.commit('addTag', tagValue)
      }
    }

    const _onRemove = (tag: any) => {
      const tagValue = tag.detail.data.value
      store.commit('removeTag', tagValue)
    }

    const _onInvalid = (event: any) => {
      switch (event.detail.message) {
        case 'already exists':
          _toggleHint('Doppelgänger sind nicht erlaubt!'); break;
        case 'dublicate synonym':
          _toggleHint('Dieses Synonym existiert bereits!'); break;
        case 'not allowed':
          _toggleHint('Dies ist ein reserviertes Wort!'); break;
        default:
          _toggleHint('Dieser Tag kann nicht eingefügt werden!'); break;
      }
    }

    const _toggleHint = (message: string) =>{
      clearTimeout(hint.value.timeout)
      const timeout = setTimeout(()=>{ hint.value.isVisible = false }, 3000)
      hint.value.timeout = timeout
      hint.value.message = message
      hint.value.isVisible = true
    }

    onMounted(() => {
      // Initialize Tagify
      const tagifySettings = {
        blacklist: _blacklistWithoutThisSynonyms(),
        callbacks: {
          add: _onAdd,
          remove: _onRemove,
          invalid: _onInvalid
        }
      }
      tagInput = new Tagify(inputEl.value, tagifySettings)
      tagInput.addTags(props.synonyms)
    })

    return {
      hint,
      store,
      inputEl,
      draft,
      tagInput,
    }
  },
  methods: {
    handleTagsChange(e: any){
      try {
        this.draft.synonyms = e.target.value ? JSON.parse(e.target.value).map((item: {value: string}) => item.value) : []
      } catch (error) { console.log("Value wasn't array", e.target.value) }
    },
    async handleTagsSave(){
      try {
        await this.store.dispatch("updateClipSynonyms", {
          editionId: this.$props.editionId,
          clipId: this.$props.clipId,
          synonyms: this.draft.synonyms
        })
      } catch (error) {
        console.error(error)
        alert("Aus irgendeinem Grund konnten die Synonyms nicht geändert werden. Bitte laden Sie die Seite neu und versuchen es noch einmal.")
      }
    },
    haveSynonymsChanged(){
      return JSON.stringify(this.synonyms) != JSON.stringify(this.draft.synonyms)
    }
  },
})
</script>

<style lang="scss">
.tagify {
  width: 280px;
}
.hint {
  color: red;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
