import { createStore } from 'vuex'
import {auth, db} from '@/services/firebaseAdapter'
import {ClipInterface} from '@/interfaces/ClipInterface'
import {EditionInterface} from '@/interfaces/EditionInterface'

const _replaceClipProperty = (clips: ClipInterface[], clipId: string, key: string, value:any) => {
  return clips.slice().map(clip => {
    if(clipId != clip.id) return clip
    // update the key value pair for the given clipId 
    const newClip = Object.assign({}, clip)
    newClip[key] = value
    return newClip
  })
}

export default createStore({
  state: {
    user: {
      isSignedIn: false
    },
    edition: {} as EditionInterface,
    clips: [] as Array<ClipInterface>,
    tags: [] as Array<string>
  },
  mutations: {
    setUserSignedInState(state, payload){ state.user.isSignedIn = payload },
    setEdition(state, payload: EditionInterface){ state.edition = payload },
    setClips(state, payload: Array<ClipInterface>){ state.clips = payload },
    addTag(state, payload: string){ state.tags.push(payload.toLowerCase()) },
    removeTag(state, payload: string){
      const index = state.tags.indexOf(payload.toLowerCase())
      if (index > -1) {
        state.tags.splice(index, 1)
      }
    },
    clearTags(state){ state.tags = []},
  },
  actions: {
    // *****************
    // Authentication
    // *****************
    async getAndUpdateUserAuthStatus({state, commit}){
      return commit('setUserSignedInState', !!await auth.getCurrentAuthStatus())
    },
    async signInUser({commit}){
      return await auth.signIn();
    },
    async signOutUser({commit}){
      await auth.signOut()
      commit('setUserSignedInState', false)
    },
    // *****************
    // Database
    // *****************
    async loadLatestEdition({commit}){
      const latestEdition = await db.getLatestEdition()
      if(!latestEdition) throw("ERROR: No edition found.")

      commit('setEdition', latestEdition)
    },
    async loadEditionClips({commit}, editionId: string){
      const clips = await db.getEditionClips(editionId)

      if(clips.length === 0) throw("ERROR: No Clips found.")
      this.commit('clearTags')
      commit('setClips', clips)
    },
    async updateClipSynonyms({commit, state}, payload: {editionId: string, clipId: string, synonyms: Array<string>}){
      // Update synonyms online:
      await db.updateClipSynonyms(payload.editionId, payload.clipId, payload.synonyms)
      // Update local state: 
      const updatedClips = _replaceClipProperty(state.clips, payload.clipId, "synonyms", payload.synonyms)

      commit('setClips', updatedClips)
    },
    async updateClipTitle({commit, state}, payload: {editionId: string, clipId: string, title: string}){
      // Update title online:
      await db.updateClipTitle(payload.editionId, payload.clipId, payload.title)
      // Update local state: 
      const updatedClips = _replaceClipProperty(state.clips, payload.clipId, "title", payload.title)

      commit('setClips', updatedClips)
    },
    async updateClipDescription({commit, state}, payload: {editionId: string, clipId: string, description: string}){
      // Update description online:
      await db.updateClipDescription(payload.editionId, payload.clipId, payload.description)
      // Update local state: 
      const updatedClips = _replaceClipProperty(state.clips, payload.clipId, "description", payload.description)

      commit('setClips', updatedClips)
    },
    async deleteClip({commit}, payload: {editionId: string, clipId: string}) {
      await db.deleteClip(payload.editionId, payload.clipId);
    }
  },
  getters: {
    isUserSignedIn: state => state.user.isSignedIn,
    edition: state => state.edition,
    clips: state => state.clips,
    tags: state => state.tags
  }
})
