import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { auth } from '@/services/firebaseAdapter'
import store from '@/store'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import PasswordReset from '../views/PasswordReset.vue'
import UserAction from '../views/UserAction.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false
    },
    async beforeEnter(to, from, next){
      // Redirect to Home if the user is already logged in
      if(store.getters.isUserSignedIn) next('/')
      next()
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: {
      requiresAuth: false
    },
    component: Login,
    async beforeEnter(to, from, next){
      await store.dispatch('signOutUser')
      next('/login')
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const isAuthRequired = to.matched.some(record => record.meta.requiresAuth)
  // Check auth status on the server on every route change
  await store.dispatch('getAndUpdateUserAuthStatus')
  // Redirect user if authentication required and user isn't authenticated
  if(isAuthRequired && !store.getters.isUserSignedIn) next('/login')
  next()
})

export default router
