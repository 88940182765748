<template>
  <div class="home">
    <header>
      <h1>Der Spiegel: Preflight-Panel ✈️</h1>
    </header>

    <main v-if="!edition.isLoading">
      <form @submit="handleSearch" class="searchForm">
        <label for="search">Ausgabe finden:</label>
        <input v-model="editionSearchId" type="text" name="search" placeholder="z.B. 202005">
        <button type="submit">Ausgabe laden</button>

        <p v-if="isSearchFailed" class="errors__search">
          <strong>Es konnte keine Ausgabe mit dieser Nummer gefunden werden.</strong><br>
          Bitte geben Sie die Nummer in folgendem Format ein: YYYYNN. "YYYY" entspricht dem vollständigen Jahr (bspw. 2020) und "NN" der Ausgabennummer (bspw. 1 oder 17). Die erste Ausgabe im Jahr 2020 hätte also die Nummer 20201.
        </p>
      </form>
      
      <h2>Ausgabe: {{editionClips[0].year}} - {{editionClips[0].episode}}</h2>

      <table :key="table">
        <thead>
          <tr>
            <th>Rubrik</th>
            <th>Bild</th>
            <th>Titel - Beschreibung - Audio</th>
            <th>Länge (Sekunden)</th>
            <th>Synonyme (Voice)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(clip, iClip) of editionClips" :key="`clip-${iClip}`">
            <td>{{clip.ressort || '[kein Rubrik vorhanden]'}}</td>
            <td class="clip__bild">
              <img v-if="clip.imageUrl" :src="clip.imageUrl" alt="Clip Image">
              <span v-else>[kein Bild vorhanden]</span>
            </td>
            <td>
              <TitleInput 
                :editionId="edition.id"
                :clipId="clip.id"
                :title="clip.title"
              />
              <div class="descriptionInput">
                <DescriptionInput 
                  :editionId="edition.id"
                  :clipId="clip.id"
                  :description="clip.description"
                />
              </div>
              
              <!-- Audio -->
              <audio 
                v-if="clip.audioUrl" 
                :src="clip.audioUrl" 
                type="audio/mpeg" 
                controls 
                preload="none"
              >
                Audio Player in diesem Browser nicht unterstützt
              </audio>
              <span v-else>[kein Audio vorhanden]</span>
            </td>
            <td>
              <span>{{clip.durationSSML || '[kein "durationSSML" vorhanden]'}}</span><br>
              <small>{{clip.duration || '[keine "duration" vorhanden]'}}</small>
            </td>
            <td>
              <TagsInput 
                :editionId="edition.id"
                :clipId="clip.id"
                :synonyms="clip.synonyms" 
                :blacklist="synonymsBlacklist"
              />
            </td>
            <td>
              <button
                    color="error"
                    id="open-dialog"
                    @click="open(edition.id, clip.id)"
                  >
                      Clip löschen &nbsp;
                </button>
                <dialog
                  id="dialog"
                  width="500"
                  :retain-focus="false"
                  >
                
                <h3>
                    Sicherheitsabfrage
                  </h3>
                  <p>
                    Möchten sie diesen Clip wirklich löschen? Dieser Schritt ist unwiederruflich.
                  </p>
          
                  <div></div>
          
                  <button
                      text
                      @click="close"
                      class="button"
                      style="margin-right: 10px; background-color: #A9A9A9"
                    >
                      Abbrechen
                    </button>
                    
                    <button
                      color="error"
                      text
                      class="button"
                      @click="deleteClip()"
                    >
                    Löschen
                    </button>
                </dialog>
            </td>
          </tr>
        </tbody>
      </table>
    </main>
    <main v-else class="loading">
      <LoadingSpinner />
    </main>

    <footer>
      <router-link to="/logout">Logout</router-link>
    </footer>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, getCurrentInstance, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import TagsInput from '@/components/TagsInput.vue'
import TitleInput from '@/components/TitleInput.vue'
import DescriptionInput from '@/components/DescriptionInput.vue'
import { ClipInterface } from '@/interfaces/ClipInterface'
import { SYNONYM_BLACKLIST } from '@/constants'

export default defineComponent({
  name: 'Home',
  setup(){
    const store = useStore()
    const edition = reactive({
      id: "" as string,
      isLoading: true,
    })
    const editionClips = computed(() => store.getters.clips)
    const usedTags = store.getters.clips.map((clip: ClipInterface) => clip.synonyms).flat(1)
    const synonymsBlacklist = computed(() => ([] as Array<string>).concat(usedTags, SYNONYM_BLACKLIST) )
    const editionId = ''
    const clipId = ''
    onMounted(async () => {
      try {
        // GET latest edition and load it into local store
        await store.dispatch('loadLatestEdition')
        edition.id = store.getters.edition.id
        // GET clips of the latest edition
        await store.dispatch('loadEditionClips', edition.id)
      } catch (error) {
        console.error(error)
        alert("Es gab einen Fehler beim Laden der aktuellen Ausgabe. Bitte versuchen Sie es später erneut.")
      } finally {
        edition.isLoading = false
      }
    })

    return {
      store,
      edition,
      editionClips,
      synonymsBlacklist,
      editionId,
      clipId
    }
  },
  data(){
    return {
      editionSearchId: "",
      isSearchFailed: false,
      table: 0
    }
  },
  methods: {
    async handleSearch(e: Event){
      e.preventDefault()

      try {
        this.edition.isLoading = true
        this.isSearchFailed = false
        await this.store.dispatch('loadEditionClips', this.editionSearchId)
        this.editionClips = this.store.getters.clips
        this.edition.id = this.editionSearchId
      } catch (error) {
        console.error(error)
        this.isSearchFailed = true
      } finally {
        this.edition.isLoading = false
      }
    },

    async deleteClip() {
      try {
        let editionId = this.editionId;
        let clipId = this.clipId;
        await this.store.dispatch('deleteClip', {editionId, clipId})
      } catch (error) {
        console.error(error);
      } finally {
        let myDialog:any = <any> document.getElementById("dialog")
        await myDialog.close();
        this.editionSearchId = this.editionId;
        let ev = new Event("click");
        this.handleSearch(ev);
        this.editionId = '';
        this.clipId = '';
        
      }
    },

    async open(editionId: any, clipId: any) {
      try {
        this.editionId = editionId;
        this.clipId = clipId;
        let myDialog:any = <any> document.getElementById("dialog")
        myDialog.showModal();
      } catch (error) {
        console.error(error)
      }
    },
    async close() {
      let myDialog:any = <any> document.getElementById("dialog")
      this.editionId = '';
      this.clipId = '';
      myDialog.close();
    }
  },
  components: {
    LoadingSpinner,
    TagsInput,
    TitleInput,
    DescriptionInput
  },
})
</script>

<style scoped lang="scss">
header,
.loading { text-align: center; }

.searchForm {
  text-align: center;
  margin: 40px 10px 60px;
  display: block;

  label {
    font-weight: 700;
    margin-right: 5px;
  }
}
.errors__search {
  color: tomato;
  font-size: .7rem;
}

table {
  width: 100%;
  text-align: left;
}
th { padding: 5px; }
td {
  padding: 5px 5px 30px;
}
td {
  vertical-align: top;
  padding-top: 20px;
}
.descriptionInput {
  margin-top: 20px;
}

td.clip__bild {
  width: 130px;

  img {
    max-width: 100%;
  }
}

main h2 { text-align: center; }

footer {
  border-top: 1px solid #ccc;
  padding: 10px;
  text-align: center;

  font-size: .7rem;
}
</style>