
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default defineComponent({
  name: 'Login',
  setup(){
    const store = useStore()

    return {
      store
    }
  },
  data(){
    return {
      loginFailed: false,
      loginLoading: false
    }
  },
  methods: {
    async handleLogin(e: Event){
      e.preventDefault()
      
      try {
        this.loginLoading = true
        this.loginFailed = false
        await this.store.dispatch('signInUser')
        // If signin succeeds go to HOME -> else show error
        this.$router.push('/')
      } catch (error) {
        console.error("ERROR Signing in", error)
        this.loginFailed = true
      } finally {
        this.loginLoading = false
      }
    }
  },
  components: {
    LoadingSpinner
  }
})
